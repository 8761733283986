@import '~@travel-ui/styles/src/utility';

.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: $spacing-s 0;
  color: $apple-red;
  font-size: $font-venus;
  line-height: $line-height-label;
  .icon {
    margin-top: $spacing-s;
    flex-shrink: 0;
  }
  .textContainer {
    padding-left: $spacing-s;
    display: flex;
    flex-direction: column;
  }
  .title {
    font-weight: bold;
  }

  &:not(.bgTransparent) {
    padding: $spacing-base;
    background: $ruddy-pink;
  }
}
